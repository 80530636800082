import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import ColumnBtnCard from '../atoms/columnBtnCard'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    span {
      display: none;
    }
  }

  ${BreakPoints.large} {
    margin-bottom: 10px;
  }
`

/**
 * 関連記事カードセット
 * TODO 川上申し送り事項：記事詳細下部に表示されるコンポーネントです。
 * ここでは、表示している記事に関連した他の記事3件が表示される予定です
 * @constructor
 */
const ColumnRelationCards0052 = () => {
  const routeParam = useRouteParam('/column/details/C0021/')
  const routeParam2 = useRouteParam('/column/details/C0022')
  const routeParam3 = useRouteParam('/column/details/C0027')
  return (
    <Wrapper>
      <ColumnH1 label="関連コラム" />
      <section>
        <ColumnBtnCard
          href={routeParam}
          imgId="c0021.jpg"
          label="年次有給休暇とは？付与の条件や日数、時期などを詳しく解説"
          date="2022.04.15"
        />
        <ColumnBtnCard
          href={routeParam2}
          imgId="c0022.jpg"
          label="有給休暇の取得義務化！企業がするべき対応やポイントとは"
          date="2022.04.15"
        />
        <ColumnBtnCard
          href={routeParam3}
          imgId="c0027.jpg"
          label="残業とは何か？残業の定義や上限時間などを解説します"
          date="2022.04.15"
        />
      </section>
    </Wrapper>
  )
}

export default ColumnRelationCards0052
