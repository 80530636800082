import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0052 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="有給休暇と残業時間の関係。残業時間が相殺されるケースとは" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.06.13</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        有給休暇と残業時間とは全く性質が異なるものの、相互に密接に関連している部分があります。この関係を理解できていない状態では適正な労務管理を進めていくことが難しくなります。今回は有給休暇と残業時間の関係について、具体例を交え、解説していきます。
      </p>
      <img src="/images/column/details/c0052.jpg" alt="キンクラコラム画像52" />
      <h1 id="toc1">有給休暇と残業時間が関連するケースとは</h1>
      <p>
        前提として、有給休暇は「本来働くべき日」に取得することができる休暇制度です。言い換えると、
        <span>有給休暇は働くべき義務のない休日には取れない</span>
        ということです。他方、休日労働と言い、休日に働くことは可能とされています。
        <br />
        <br />
        では、どのような場合に残業が発生するかと言うと、労働時間とは1日8時間・週40時間以内が法律上の枠組みとなるものの、それを超えて働かざるを得ない場合も少なくありません。最も分かりやすい例では8時30分から17時30分（途中1時間休憩）まで1日8時間労働として契約している労働者が9時間働いた（18時30分まで）場合、1時間の「残業時間」が発生します。しかし、午前中は有給休暇を取得し、13時から出勤した場合はどのように考えるべきでしょうか。結論として、
        <span>
          法律上はその日に18時30分まで働いたとしても残業時間としては扱われません。
        </span>
        その理由として、有給休暇は働いたものとみなして賃金を保障する制度ですが、実際に働いているわけではありません。1日8時間・週40時間を考えるにあたっては「実労働時間」を計算し、残業時間を計算しますので、本事例では法律上、残業時間としては扱われないこととなります。
      </p>

      <h1 id="toc2">残業時間の2つの種類</h1>
      <p>
        残業時間と言っても厳密には次の2つの残業時間があります。1つは
        <span>法定外残業</span>と2つ目は<span>法定内残業</span>
        です。会社によっては異なる呼び名を用いているケースもありますが、本記事では法定外（内）残業で話を進めていきます。
        <br />
        <br />
        <span>1日8時間・週40時間を超えて働いた分は法定外残業</span>
        と言います。これは法律上の1日8時間・週40時間の「法定労働時間」を超えて働いた分であるためとされています。次に法定内残業とは、人によっては通常の労働契約で1日7時間労働という契約をしているケースも珍しくありません。そのような場合に、仮に9時間労働をした場合、
        <span>7時間を超えて8時間までの労働を法定内残業</span>
        と呼びます。これは法律上の1日8時間・週40時間の「法定労働時間」を超えていない労働であるためとされています。そして、8時間を超えて9時間までの労働は法定外残業となります。
        <br />
        <br />
        補足として、法定内残業は通常の賃金と同額を支払えば足り、法定外残業のように通常の賃金に更に1.25倍割り増した割増賃金を支払うことは、法律上は義務とされていません。
        <br />
        <br />
        午前中に有給休暇を取り、（午後から出社）遅い時間まで勤務した場合であっても、少なくとも午前中は労働の提供がなされいないことから、（有給休暇により）午前中の賃金の保障はされても実労働時間のカウントとは別次元の話ですので、終業時刻が17時30分であり、仮にその時刻を過ぎて働いたとしても全く残業が発生しないことは理論上十分にあり得ます。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">実務上の留意点</h1>
      <p>
        法律上の理屈では、1日8時間・週40時間を超えて働いた場合に限り残業代（通常の賃金に対して1.25倍の賃金）を支払うという労務管理は問題ありません。しかし、就業規則上「終業時刻を過ぎて働いた分は割増賃金を支払う」と規定されている場合、話は異なります。
        <span>
          就業規則はその会社の最低基準のルールを定めたものであり、その内容を下回ることができません。
        </span>
        それは法律を上回る内容を定めていた場合であっても、既に規定を定め労働者に周知している場合はその内容がその会社の「最低基準」となります。
        <br />
        <br />
        このことが、何を意味するかと言うと、仮に8時30分が始業、17時30分が終業の会社の場合、午前中に有給休暇を取り、午後に出社した場合であっても、17時30分以降に働いた分は残業時間として割増賃金を支払うというルールになるということです。
      </p>

      <h1 id="toc4">相殺は可能？</h1>
      <p>
        例えば2日連続で午前中に有給休暇を取得し、初日は実労働時間として6時間働き、翌日は実労働時間として10時間働いた場合を検証します。会社目線では、経営的にも割増賃金は可能な限り回避したいものですが、翌日の2時間分の残業(10時間労働-法定労働時間である8時間=2時間)を初日に当て込んで両日、
        <span>残業ゼロという対応は取れません。</span>
        <br />
        <br />
        参考までにフレックスタイム制であれば原則として、月あたりの総労働時間数で残業時間を計算するため、一日経過時点では残業時間として確定しませんが、通常の固定労働時間制では日を跨いで相殺するという対応は違法となります。
      </p>

      <h1 id="toc5">最後に</h1>
      <p>
        割合的に有給休暇を取得した日に残業するというケース自体、あまり多くはないのでしょうが、そもそも残業という性質上、労働者の業務スケジュールとは無関係に突発的に発生することがありますので、
        <span>どのような場合に法律上の残業時間として扱われるのか</span>
        と言う部分と、自社の
        <span>就業規則の規定内容は必ず確認しておくべき</span>論点です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0052
